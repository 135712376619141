.login-container {
    padding: 20px;
  }
  
  .login-form, .add-user-form, .publish-section, .articles-section {
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  .input, .input-file, .textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .buttonn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .buttonn.delete-button {
    background-color: #dc3545;
  }
  
  .articles-section {
    margin-top: 20px;
  }
  
  .articles-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .article-card {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    box-sizing: border-box;
  }
  
  .article-card img {
    width: 100%;
    height: auto;
   object-fit: cover;
  }
  
  .article-card h4 {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .article-card p {
    margin: 5px 0;
  }
  


  .buttunn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition-duration: .3s;
    overflow: hidden;
    position: relative;
  }
  
  .svgIcon {
    width: 12px;
    transition-duration: .3s;
  }
  
  .svgIcon path {
    fill: white;
  }
  
  .buttunn:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: .3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
  }
  
  .buttunn:hover .svgIcon {
    width: 50px;
    transition-duration: .3s;
    transform: translateY(60%);
  }
  
  .buttunn::before {
    position: absolute;
    top: -20px;
    content: "Delete";
    color: white;
    transition-duration: .3s;
    font-size: 2px;
  }
  
  .buttunn:hover::before {
    font-size: 13px;
    opacity: 1;
    transform: translateY(30px);
    transition-duration: .3s;
  }



  .buttttt {
    display: flex;
    gap: 20px;
    align-items: center;
  }


  .edit-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    text-decoration: none !important;
  }
  
  .edit-svgIcon {
    width: 17px;
    transition-duration: 0.3s;
  }
  
  .edit-svgIcon path {
    fill: white;
  }
  
  .edit-button:hover {
    width: 120px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
  }
  
  .edit-button:hover .edit-svgIcon {
    width: 20px;
    transition-duration: 0.3s;
    transform: translateY(60%);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
  .edit-button::before {
    display: none;
    content: "Edit";
    color: white;
    transition-duration: 0.3s;
    font-size: 2px;
  }
  
  .edit-button:hover::before {
    display: block;
    padding-right: 10px;
    font-size: 13px;
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.3s;
  }
  

  .custum-file-upload {
    height: 200px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dashed #cacaca;
    background-color: rgba(255, 255, 255, 1);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 48px 35px -48px rgba(0,0,0,0.1);
  }
  
  .custum-file-upload .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custum-file-upload .icon svg {
    height: 80px;
    fill: rgba(75, 85, 99, 1);
  }
  
  .custum-file-upload .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custum-file-upload .text span {
    font-weight: 400;
    color: rgba(75, 85, 99, 1);
  }
  
  .custum-file-upload input {
    display: none;
  }



  .publish-btn {
    padding: 0.1em 0.25em;
    width: 13em;
    height: 4.2em;
    background-color: #dedede;
    border: 0.08em solid #fff;
    border-radius: 0.3em;
    font-size: 12px;
    cursor: pointer;
  }
  
  .publish-btn span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0.4em;
    width: 8.25em;
    height: 2.5em;
    background-color: #676767;
    border-radius: 0.2em;
    font-size: 1.5em;
    color: #fff;
    border: 0.08em solid #fff;
    box-shadow: 0 0.4em 0.1em 0.019em #fff;
  }
  
 .publish-btn span:hover {
    transition: all 0.5s;
    transform: translate(0, 0.4em);
    box-shadow: 0 0 0 0 #fff;
  }
  
  .publish-btn span:not(hover) {
    transition: all 1s;
  }
  