/* News.css */

  .news-container {
    padding: 40px 20px;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 10px;
  }
  
  .news-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .news-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .news-card {
    transition: transform 0.3s;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  .news-image {
   height: 200px;

  }
  
  .news-content {
    padding: 20px;
  }
  
  .news-card-title {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .news-article {
    font-size: 1em;
    color: #555;
    margin-bottom: 15px;
  }
  
  .news-author {
    font-size: 0.9em;
    color: #999;
  }
  
  .news-author strong {
    color: #333;
  }
  
  .news-category,
  .news-date {
    font-size: 0.8em;
    color: #777;
  }
  